.input {
  display: block;
  width: 100%;
  height: var(--control-height);
  padding: 15px 11.25px;
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--main-color);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (min-width: 768px) {
    font-size: 15px;
  }

  &:focus {
    background-color: #f8f9fa;
    border-color: var(--active-color);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(39, 80, 155, 0.25);
  }

  &::placeholder {
    color: #676767;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}

@media (prefers-reduced-motion: reduce) {
  .input {
    transition: none;
  }
}
