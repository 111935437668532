.label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.7rem;
  font-family: var(--font-family-bold-sans-serif);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.label .label {
  width: auto;
}
