:root {
  --control-height: 53.6px;
}

.dropdown {
  z-index: 103;
  font-family: var(--font-family-sans-serif);
  font-size: 15px;
  border-top: 0;
  border-radius: 0;
  box-shadow: none;

  & :global(.rc-select-dropdown-menu) {
    max-height: 250px;
  }
}

.select {
  display: block;

  & * {
    font-family: var(--font-family-sans-serif);
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 15px;
    }
  }

  & :global(.rc-select-selection__rendered) {
    height: var(--control-height);
    line-height: var(--control-height);
  }

  & :global(.rc-select-selection) {
    height: var(--control-height);
    padding-right: 37.5px;
    padding-left: 11.25px;
    line-height: var(--control-height);
    border-color: #ced4da;
    border-radius: 0;
    box-shadow: none;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  & :global(.rc-select-arrow .rc-select-arrow-icon) {
    position: absolute;
    top: 22px;
    right: 12px;
    left: auto;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #e20109 transparent transparent;
  }

  &:global(.rc-select-focused .rc-select-selection) {
    border-color: var(--active-color) !important;
  }

  &:global(.rc-select-open .rc-select-arrow .rc-select-arrow-icon) {
    top: 16px;
    border-color: transparent transparent var(--active-color);
  }

  &:global(.rc-select-enabled .rc-select-selection:hover) {
    border-color: #ced4da;
    box-shadow: none;
  }
}
