.root {
  display: flex !important;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .root {
    flex-direction: column;
  }
}

.select {
  position: relative;
  z-index: 2;
  width: 160px;
  min-width: 160px;
}

@media (max-width: 768px) {
  .select {
    width: 100%;
  }
}

.select :global(.rc-select-selection__rendered) {
  margin: 0;
}

.input {
  position: relative;
  top: 31px;
  width: 100%;
  margin-left: -1px;
}

@media (max-width: 768px) {
  .input {
    top: 0;
    margin-top: -1px;
    margin-left: 0;
  }
}

.input input {
  position: relative;
  z-index: 1;
}

.input input:focus {
  z-index: 2;
}
