.field {
  /* styles */
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  font-family: var(--font-family-sans-serif);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.hint {
  margin-top: 4px;
}
