.native {
  display: none;
}

.checkbox {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border: 1px solid var(--border-light);
}

.checkbox:focus {
  outline: none;
}

.native:checked ~ .checkbox::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448.8 448.8'%3E%3Cpolygon fill='%23e30810' points='142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55' /%3E%3C/svg%3E");
}

.native:checked:disabled ~ .checkbox::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448.8 448.8'%3E%3Cpolygon fill='%23e0e6e9' points='142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55' /%3E%3C/svg%3E");
}
.native:disabled ~ .checkbox {
  background-color: var(--bg-gray);
}

.label {
  margin-left: 10px;
  vertical-align: middle;
}
