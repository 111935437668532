.term {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  & [class^='label'] {
    width: calc(100% - 35px);
    margin-top: 3px;
  }
}

.termLink {
  color: var(--link-color);
  text-decoration: none;
  border-bottom: 1px dashed;
}
