.title {
  margin-top: 0;
  margin-bottom: 22.5px;
  font-family: var(--font-family-bold-sans-serif);
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.h1 {
  font-weight: 700;
  color: var(--title-dark);

  &.title {
    font-family: var(--font-family-headers-sans-serif);
    font-size: 2rem;

    @media (--media-sm) {
      font-size: 2.8rem;
    }

    @media (--media-md) {
      font-size: 3.2rem;
    }

    @media (--media-lg) {
      font-size: 3.5rem;
    }
  }
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}
