.modal {
  position: relative;
  display: inline-block;
  max-width: 1110px;
  padding: 22.5px;
  margin: auto;
  text-align: left;
  vertical-align: middle;
  background: #fff;
  outline: none;
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.15);

  @media (--media-sm) {
    padding: 2.25rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  overflow-y: auto;
  text-align: center;

  &::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  @media (--media-sm) {
    padding: 0 15px;
  }
}

:global(.ReactModal__Body--open),
:global(.ReactModal__Html--open) {
  overflow: hidden;
}
