:root {
  --hor-padding: 28px;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--control-height);
  padding: 0 var(--hor-padding);
  font-family: var(--font-family-sans-serif);
  font-size: 15px;
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--main-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: var(--main-color);
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(39, 80, 155, 0.25);
  }

  &:disabled {
    opacity: 0.65;
  }
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.primary {
  color: #fff;
  text-transform: uppercase;
  background-color: #27509b;
  border-color: #1d3b72;
  border-radius: 1rem 0;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #003145;
  }

  &:focus {
    box-shadow: 0 0 0 0 rgba(71, 106, 170, 0.5);
  }

  &:disabled {
    color: #fff;
    background-color: #27509b;
    border-color: #27509b;
  }

  &:not(:disabled):active {
    color: #fff;
    background-color: #1d3b72;
    border-color: #1a3668;
  }

  &:not(:disabled):active:focus {
    box-shadow: 0 0 0 0 rgba(71, 106, 170, 0.5);
  }
}

.wide {
  width: 100%;
}

.icon-arrow {
  padding-right: calc(var(--hor-padding) + 7px);

  &::after {
    position: absolute;
    top: 0;
    right: calc((var(--hor-padding) + 7px) / 2 - 3.5px);
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto 0;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid #e20109;
  }

  &:hover::after {
    border-left: 7px solid #fff;
  }
}
