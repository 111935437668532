.h3 {
  margin: 0 0 24px 0;
  font-family: var(--font-family-sans-serif);
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--title-dark);
}

.field {
  display: block;
  margin-bottom: 30px;
}

.field :global [class^='hint'],
.termCheckbox :global [class^='hint'] {
  position: relative;
  width: 100%;
}

.field :global [class^='hint'] > span,
.termCheckbox :global [class^='hint'] > span {
  position: absolute;
  top: 0;
  left: 0;
}

.termCheckbox {
  margin-bottom: 45px;
}
